import React, { Component } from "react";
import { connect } from "react-redux";
import "./patient-medical.css";
import moment from "moment";
import MedicalPop from "./medicalPop";
import AddTreatment from "./addTreatment";
import DividLine from "./dividLine";
import { Button } from "antd";
import _ from "lodash";
import { updateFootTreatmentStatus } from "../action/patientMedicalAction";
import UpdateTreatment from "./updateTreatment";

/**
 *  Created by shengli.zhang on 4/30/21 3:17 PM
 */
const DOWN = require("../img/down_blue.png");
const UP = require("../img/up_blue.png");
class FootTreatment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addAnotherTreatment: false,
      initWithNo: false,
      loadings: [],
      viewAll: true,
      treatmentData: []
    };
  }

  componentDidMount() {
    this.setState({
      treatmentData:
        this.props.type !== "ADD" && this.props.type !== "PROFILE"
          ? this.props.serviceInfo && this.props.serviceInfo.footTreatments
            ? this.props.serviceInfo.footTreatments
            : []
          : this.props.patientDetails &&
            this.props.patientDetails.patientMedicalInfo &&
            this.props.patientDetails.patientMedicalInfo.footTreatments
          ? this.props.patientDetails.patientMedicalInfo.footTreatments
          : []
    });
  }

  markCompleted = (userId, reportTime, status, index) => {
    this.setState(({ loadings }) => {
      const newLoadings = [...loadings];
      newLoadings[index] = true;
      return {
        loadings: newLoadings
      };
    });
    const input = {
      userId,
      reportTime,
      status,
      endTime: moment().format("YYYY-MM-DD")
    };
    this.props.updateFootTreatmentStatus(input, this, index);
  };

  markActive = (userId, reportTime, status, index) => {
    this.setState(({ loadings }) => {
      const newLoadings = [...loadings];
      newLoadings[index] = true;
      return {
        loadings: newLoadings
      };
    });
    const input = { userId, reportTime, status };
    this.props.updateFootTreatmentStatus(input, this, index);
  };

  renderCompletedCard(p, index) {
    return (
      <div
        key={p.reportTime}
        style={{
          backgroundColor: "#3F3F3F",
          borderRadius: 5,
          width: "60%",
          minWidth: 500,
          marginTop: 20,
          color: "#FFF",
          display: this.state.viewAll ? "block" : "none"
        }}
      >
        <div style={{ display: this.state.viewAll ? "flex" : "none" }}>
          <div style={{ width: "80%" }}>
            <div
              style={{
                fontWeight: "bold",
                fontSize: 15,
                marginLeft: 20,
                paddingTop: 10
              }}
            >
              {p.footTreatment.toUpperCase()}, {p.foot} Foot
            </div>
            <div
              style={{
                fontSize: 14,
                marginLeft: 20,
                paddingTop: 5
              }}
            >
              <span style={{ display: "inline-block", width: 120 }}>
                Start Date:
              </span>
              <strong
                style={{
                  fontWeight: "bold"
                }}
              >
                {moment(p.startTime).format("MM/DD/YYYY")}
              </strong>
            </div>
            <div
              style={{
                fontSize: 14,
                marginLeft: 20,
                paddingTop: 5,
                paddingBottom: p.followUpTime ? 0 : 10
              }}
            >
              <span style={{ display: "inline-block", width: 120 }}>
                End Date:
              </span>
              <span
                style={{
                  fontWeight: p.endTime ? "bold" : "0"
                }}
              >
                {p.endTime ? moment(p.endTime).format("MM/DD/YYYY") : "Ongoing"}
              </span>
            </div>
            {p.followUpTime && (
              <div
                style={{
                  fontSize: 14,
                  marginLeft: 20,
                  paddingTop: 5,
                  paddingBottom: 10
                }}
              >
                <span style={{ display: "inline-block", width: 120 }}>
                  Follow-up Date:
                </span>
                <span
                  style={{
                    color: p.endTime ? "#000" : "#8A8E9D",
                    fontWeight: p.endTime ? "bold" : "0"
                  }}
                >
                  {moment(p.followUpTime).format("MM/DD/YYYY")}
                </span>
              </div>
            )}
          </div>
          {!this.props.canEdit ? null : (
            <div style={{ display: "flex", width: "20%" }}>
              <Button
                type="link"
                loading={this.state.loadings[index]}
                onClick={() =>
                  this.markActive(
                    this.props.patientDetails.userId,
                    p.reportTime,
                    "Reactivate",
                    index
                  )
                }
                style={{ margin: "0 auto" }}
              >
                Reactivate
              </Button>
            </div>
          )}
        </div>
        <div
          style={{
            fontSize: 14,
            marginLeft: 20,
            paddingBottom: 10
          }}
        >
          This treatment has been marked as done.
        </div>
      </div>
    );
  }

  renderOngoingCard(p, index) {
    return (
      <div
        key={p.reportTime}
        style={{
          backgroundColor: "#F8F8FA",
          borderRadius: 5,
          width: "60%",
          minWidth: 500,
          display: this.state.viewAll ? "flex" : "none",
          marginTop: 20
        }}
      >
        <div style={{ width: "60%" }}>
          <div
            style={{
              fontWeight: "bold",
              fontSize: 15,
              marginLeft: 20,
              paddingTop: 10
            }}
          >
            {p.footTreatment.toUpperCase()}, {p.foot} Foot
          </div>
          <div
            style={{
              fontSize: 14,
              marginLeft: 20,
              paddingTop: 5
            }}
          >
            <span style={{ display: "inline-block", width: 120 }}>
              Start Date:
            </span>
            <span
              style={{
                fontWeight: p.endTime ? "bold" : "0",
                color: "#000"
              }}
            >
              {moment(p.startTime).format("MM/DD/YYYY")}
            </span>
          </div>
          <div
            style={{
              fontSize: 14,
              marginLeft: 20,
              paddingTop: 5,
              paddingBottom: p.followUpTime ? 0 : 10
            }}
          >
            <span style={{ display: "inline-block", width: 120 }}>
              End Date:
            </span>
            <span
              style={{
                color: p.endTime ? "#000" : "#8A8E9D",
                fontWeight: p.endTime ? "bold" : "0"
              }}
            >
              {p.endTime ? moment(p.endTime).format("MM/DD/YYYY") : "Ongoing"}
            </span>
          </div>
          {p.followUpTime && (
            <div
              style={{
                fontSize: 14,
                marginLeft: 20,
                paddingTop: 5,
                paddingBottom: 10
              }}
            >
              <span style={{ display: "inline-block", width: 120 }}>
                Follow-up Date:
              </span>
              <span
                style={{
                  color: p.endTime ? "#000" : "#8A8E9D",
                  fontWeight: p.endTime ? "bold" : "0"
                }}
              >
                {moment(p.followUpTime).format("MM/DD/YYYY")}
              </span>
            </div>
          )}
        </div>
        {!this.props.canEdit ? null : (
          <div style={{ display: "flex", width: "40%" }}>
            <Button
              type="link"
              loading={this.state.loadings[index]}
              onClick={() =>
                this.markCompleted(
                  this.props.patientDetails.userId,
                  p.reportTime,
                  "Completed",
                  index
                )
              }
            >
              Mark Completed
            </Button>
            <UpdateTreatment
              treatmentInfo={p}
              updateTreatmentData={data => {
                let nextTreatmentData = [];
                if (
                  _(this.state.treatmentData).find({
                    reportTime: data.reportTime
                  })
                ) {
                  nextTreatmentData = this.state.treatmentData.map(item => {
                    if (item.reportTime === data.reportTime) {
                      return {
                        ...data,
                        type: item.type
                      };
                    }
                    return item;
                  });
                } else {
                  nextTreatmentData = [...this.state.treatmentData, data];
                }
                this.setState({ treatmentData: nextTreatmentData });
                if (this.props.onChangeFootTreatmentData) {
                  this.props.onChangeFootTreatmentData(nextTreatmentData);
                }
              }}
            />
          </div>
        )}
      </div>
    );
  }

  render() {
    let hasData = this.state.treatmentData.length > 0;
    let initialState = !hasData;
    if (this.state.addAnotherTreatment) {
      initialState = false;
    }
    const initReadonly = initialState && !this.props.canEdit;
    return (
      <div>
        <div
          style={{
            fontWeight: "bold",
            fontSize: 18,
            marginBottom: 10,
            marginTop: 10,
            display: "flex",
            alignItems: "baseline"
          }}
        >
          Treatment
          <MedicalPop
            title="Treatment"
            content={
              "Medical care prescribed to a patient for an injury or a condition."
            }
          />
          <Button
            type="link"
            size="small"
            style={{
              display: hasData ? "block" : "none"
            }}
            onClick={() => {
              this.setState({ viewAll: !this.state.viewAll });
            }}
          >
            View all
            <img
              src={this.state.viewAll ? UP : DOWN}
              style={{ width: 12, height: 16, marginLeft: 5 }}
            />
          </Button>
        </div>
        {this.props.type === "PROFILE" && (
          <div>
            For view only. To change any treatments, go to{" "}
            <Button
              type="link"
              style={{ marginLeft: -15 }}
              onClick={() => {
                this.props.editFootMedical("2");
              }}
            >
              DMT
            </Button>
          </div>
        )}
        {hasData &&
          _.orderBy(
            this.state.treatmentData,
            ["status", "reportTime"],
            ["asc", "desc"]
          ).map((p, index) =>
            p.status === "Completed"
              ? this.renderCompletedCard(p, index)
              : this.renderOngoingCard(p, index)
          )}
        {initReadonly ? (
          <div>No treatments at this time.</div>
        ) : initialState ? (
          this.state.initWithNo ? (
            <>
              <div>No treatments at this time.</div>
              <Button
                type="link"
                style={{
                  marginTop: 20,
                  marginLeft: -16
                }}
                onClick={() => this.setState({ addAnotherTreatment: true })}
              >
                + Add a treatment
              </Button>
            </>
          ) : (
            <>
              <div>
                Nothing has been added yet. Does the patient have any
                treatments?
              </div>
              <div style={{ marginTop: 30, marginBottom: 30 }}>
                <Button
                  style={{ marginRight: 12 }}
                  onClick={() => this.setState({ addAnotherTreatment: true })}
                >
                  Yes
                </Button>
                <Button
                  onClick={() => {
                    this.setState({ initWithNo: true });
                    this.props.onChangeFootTreatmentStatus("No");
                  }}
                >
                  No change to treatment
                </Button>
              </div>
            </>
          )
        ) : (
          <>
            {this.state.addAnotherTreatment && (
              <AddTreatment
                cancelTreatment={() => {
                  this.setState({ addAnotherTreatment: false });
                }}
                updateTreatmentData={data => {
                  const nextTreatmentData = [...this.state.treatmentData, data];
                  this.setState({
                    treatmentData: nextTreatmentData,
                    addAnotherTreatment: false
                  });
                  if (this.props.onChangeFootTreatmentData) {
                    this.props.onChangeFootTreatmentData(nextTreatmentData);
                  }
                }}
              />
            )}
            {this.props.canEdit && (
              <Button
                type="link"
                style={{
                  marginTop: 20,
                  marginLeft: -16
                }}
                onClick={() => this.setState({ addAnotherTreatment: true })}
              >
                + Add a treatment
              </Button>
            )}
          </>
        )}
        <DividLine />
      </div>
    );
  }
}

const mapStateToProp = state => ({
  patientDetails: state.patientsListStore.doctorDetails
});
const mapDispatchToProp = dispatch => ({
  updateFootTreatmentStatus: (input, self, index) =>
    dispatch(updateFootTreatmentStatus(input, self, index))
});
export default connect(mapStateToProp, mapDispatchToProp)(FootTreatment);
