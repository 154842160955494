import { QuestionCircleFilled } from "@ant-design/icons";
import { Icon } from "antd";
import "antd/dist/antd.css";
import PropTypes from 'prop-types';
import React, { Component } from "react";
import { Popup } from "semantic-ui-react";
import "./biMonthly.css";
import "./report.css";

const UNBILLED_RECORD = require("../img/Unbilled.png");

export default class ReportHeaderBase extends Component {

  state = {
    tab: 0
  }

  componentDidMount() {
    for (let i = 0; i < this.tabs.length; i++) {
      const tab = this.tabs[i];
      if (window.location.href.includes(tab.path)) {
        this.setState({
          tab: i
        });
        break;
      }
    }
  }

  renderHelperPopup() {
    return (
      <Popup
        trigger={<QuestionCircleFilled className="summary_icon" />}
        position="bottom center"
        hoverable
        flowing
      >
        <Popup.Header className="popHeader">
          Report Terminology
        </Popup.Header>
        <Popup.Content>
          <div className="popBox">
            {
              this.terminology.map(item => (
                <div className="popContainer" key={item.term}>
                  <p className="popTitle">{item.term}</p>
                  <p className="popContent">{item.body}</p>
                </div>
              ))
            }
          </div>
        </Popup.Content>
      </Popup>
    )
  }

  renderStatBanner() {
    return (
      <div
        className="report_des_container_dark"
        style={{ height: 100, width: 314 }}
      >
        <div style={{ display: "flex" }}>
          <img
            src={UNBILLED_RECORD}
            alt=""
            style={{ width: 70, height: 70, marginLeft: 20 }}
          />
          <div style={{ marginLeft: 10 }}>
            <div style={{ color: "#f3f3f3", fontSize: 30 }}>
              <strong>{this.props.reviewRecordsCount}</strong>
            </div>
            <div style={{ color: "#f3f3f3", fontSize: 16 }}>
              <strong>Total Records for Review</strong>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="tab_container">
        <div className="tab_title report_des_container">
          <p>
            <strong>{this.title}</strong>
          </p>
          <Icon
            type="reload"
            title="reset all filters"
            style={{
              position: "absolute",
              right: 52,
              top: 10
            }}
            onClick={this.props.onResetFilter}
          />
          {this.renderHelperPopup()}
        </div>
        {this.renderStatBanner()}
        {
          this.tabs.map((tab, i) => (
            <span
              key={i}
              className={
                this.state.tab === i ? "tab_nav-active" : "tab_nav"
              }
              onClick={tab.onClick}
            >
              {tab.name}
            </span>
          ))
        }
      </div>
    );
  }
}

ReportHeaderBase.propTypes = {
  reviewRecordsCount: PropTypes.number.isRequired,
  onResetFilter: PropTypes.func,
}
