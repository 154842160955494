import React, { Component } from "react";
import { InfoCircleFilled } from "@ant-design/icons";
import { Popup } from "semantic-ui-react";
/**
 *  Created by shengli.zhang on 4/30/21 2:11 PM
 */

export default class MedicalPop extends Component {
  render() {
    return (
      <div>
        <Popup
          trigger={
            <InfoCircleFilled
              style={{
                color: "#D3D6E1",
                fontSize: 16,
                marginLeft: 5
              }}
            />
          }
          position="top center"
          hoverable
          flowing
          style={{ backgroundColor: "#F1F1F1", width: 450 }}
        >
          <Popup.Header
            style={{
              marginTop: 5,
              fontSize: 16
            }}
          >
            {this.props.title}
          </Popup.Header>
          <Popup.Content>{this.props.content}</Popup.Content>
        </Popup>
      </div>
    );
  }
}
