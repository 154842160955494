import { Control } from "react-keeper";
import { connect } from "react-redux";
import ReportHeaderBase from "./ReportHeaderBase";

class ReportHeader99457_8 extends ReportHeaderBase {

  title = 'RPM: 99457 & 99458'

  terminology = [
    {
      term: 'Data Monitoring Service:',
      body: 'Overview of time spent by clinical staff/physician with patient/caregiver in a calendar month.',
    },
    {
      term: 'Account Created:',
      body: "Patient's account creation date in the Siren Dashboard.",
    },
    {
      term: 'Account Activation Date:',
      body: 'First date that patient logged into the Siren App.',
    },
    {
      term: 'Socks Dispensed Date:',
      body: 'Date that Siren Socks were first provided to the patient (issued by clinic or shipped by Siren).',
    },
    {
      term: 'Total Time:',
      body: 'Sum of time tracked by staff in "Data Monitoring Tracker".',
    },
    {
      term: 'Days Remaining:',
      body: 'Number of days remaining in the month.',
    },
  ]

  tabs = [
    // {
    //   name: 'Monitoring',
    //   path: '/MonitoringReport',
    //   onClick: () => Control.go("/MonitoringReport")
    // },
    {
      name: 'Needs Review',
      path: '/MonitoringNeedsReview',
      onClick: () => Control.go("/MonitoringNeedsReview")
    },
    {
      name: 'Reviewed',
      path: '/MonitoringReviewed',
      onClick: () => Control.go("/MonitoringReviewed")
    }
  ]

}

const mapStateToProp = state => ({
  reviewRecordsCount: state.monthlyReportStore.count99457Unbilled
});

export default connect(mapStateToProp, null)(ReportHeader99457_8);
