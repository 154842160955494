import React from "react";
import "./AccountStatus.css";
/**
 *  Created by shengli.zhang on 12/11/21 4:08 PM
 */

export default function CircleColor(props) {
  return (
    <div
      // className="circle_status_icon"
      style={{
        width: "15px",
        height: "15px",
        borderRadius: "50%",
        verticalAlign: "middle",
        display: "inline-block",
        backgroundColor: props.color,
        marginRight: 5
      }}
    />
  );
}
