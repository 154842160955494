import React, { Component } from "react";
import "./DateInput.css";

const DefaultDateFormat = 'YYYY-MM-DD'
const DateReg = /^[0-9/-]*$/
const ValidDatePattern = '\\d{4}([-/]\\d{2}){0,2}'
const ValidDateReg = new RegExp(ValidDatePattern)

export default class DateInput extends Component {

	constructor(props) {
		super(props)

		this.state = {
			value: props.defaultValue || ''
		}
		if (this.props.pattern) {
			this.ValidDateReg = new RegExp(this.props.pattern) 
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.defaultValue !== prevProps.defaultValue) {
			this.setState({
				value: this.props.defaultValue || ''
			})
		}
	}

	onDateChange = e => {
		let { value } = e.target
		if (!DateReg.test(value) || value.length > 10) {
			return
		}

		this.setState({value}, this.changeCallback)
	}

	onPressEnter = () => {
		if (!this.validateDate()) {
			return
		}
		if (typeof this.props.onPressEnter === 'function') {
			this.props.onPressEnter(this.state.value)
		}
	}

	changeCallback = () => {
		if (!this.validateDate()) {
			return
		}
		if (typeof this.props.onChange === 'function') {			
			this.props.onChange(this.state.value)
		}
	}

	validateDate = () => {
		if (this.ValidDateReg) {
			return this.state.value === '' || this.ValidDateReg.test(this.state.value)
		}
		return this.state.value === '' || ValidDateReg.test(this.state.value)
	}

	formatOutput = () => {
		let dateString = this.state.value
		if (dateString.length === 10) {
			// DD-MM-YYYY to YYYY-MM-DD
			dateString = `${dateString.substring(6, 10)}-${dateString.substring(3, 5)}-${dateString.substring(0, 2)}`
		} else if (dateString.length === 7) {
			// MM-YYYY to YYYY-MM
			dateString = `${dateString.substring(3, 7)}-${dateString.substring(0, 2)}`
		}

		return dateString
	}

	render() {
		// defaultValue is used to init this.state.value
		let {onChange, onPressEnter, defaultValue, ...rest} = this.props
		rest = Object.assign({
			placeholder: DefaultDateFormat,
			style: { width: '100%' },
			pattern: ValidDatePattern // DD-MM-YYYY or MM-YYYY or YYYY
		}, rest)

		return (
			<input
				className='date-input'
				{...rest}
				value={this.state.value}
				onChange={this.onDateChange}
				onKeyPress={event => {
					if (event.keyCode === 13 || event.which === 13) {
						this.onPressEnter(event)
					}
				}}
			/>
		)
	}
}