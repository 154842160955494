import React, { Component } from "react";
import { connect } from "react-redux";
import { Select, Modal, DatePicker, Button, Row, Col } from "antd";
import { FOOT, TREATMENT } from "../tools/patient-medical";
import moment from "moment";
import { updateFootTreatment } from "../action/patientMedicalAction";

/**
 *  Created by shengli.zhang on 4/30/21 3:17 PM
 */
const dateFormat = "MM/DD/YYYY";
class UpdateTreatment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectTreatment: undefined,
      selectFoot: undefined,
      startDate: moment().format("YYYY-MM-DD"),
      endDate: undefined,
      followUpDate: undefined,
      loadings: [],
      visible: false
    };
  }
  showModal = () => {
    this.setState({
      visible: true,
      selectTreatment: this.props.treatmentInfo.footTreatment,
      selectFoot: this.props.treatmentInfo.foot,
      startDate: this.props.treatmentInfo.startTime,
      endDate: this.props.treatmentInfo.endTime,
      followUpDate: this.props.treatmentInfo.followUpTime
    });
  };

  handleTreatmentChange = value => {
    this.setState({ selectTreatment: value });
  };

  handleFootChange = value => {
    this.setState({ selectFoot: value });
  };

  onStartDateChanged = (date, dateString) => {
    if (date) {
      this.setState({
        startDate: moment(date).format("YYYY-MM-DD")
      });
    }
  };

  onEndDateChanged = (date, dateString) => {
    if (date) {
      this.setState({
        endDate: moment(date).format("YYYY-MM-DD")
      });
    } else {
      this.setState({
        endDate: undefined
      });
    }
  };

  onFollowUpDateChanged = (date, dateString) => {
    if (date) {
      this.setState({
        followUpDate: moment(date).format("YYYY-MM-DD")
      });
    } else {
      this.setState({
        followUpDate: undefined
      });
    }
  };

  saveTreatment = index => {
    if (!this.state.selectTreatment) {
      Modal.warning({
        title: "Save Treatment Failed",
        content: "Please select a treatment.",
        centered: true
      });
      return;
    }
    if (!this.state.selectFoot) {
      Modal.warning({
        title: "Save Treatment Failed",
        content: "Please select foot.",
        centered: true
      });
      return;
    }
    const input = {
      userId: this.props.doctorDetails.userId,
      reportTime: this.props.treatmentInfo.reportTime,
      footTreatment: this.state.selectTreatment,
      foot: this.state.selectFoot,
      startTime: this.state.startDate,
      status: "InCompleted"
    };
    if (this.state.endDate) {
      Object.assign(input, { endTime: this.state.endDate });
      // if (this.state.endDate < moment().format("YYYY-MM-DD")) {
      //   Modal.warning({
      //     title: "Save Treatment Failed",
      //     content: "End date must be larger than today.",
      //     centered: true
      //   });
      //   return;
      // }
      if (this.state.endDate < this.state.startDate) {
        Modal.warning({
          title: "Save Treatment Failed",
          content: "End date must be larger than Start date.",
          centered: true
        });
        return;
      }
    }
    if (this.state.followUpDate) {
      Object.assign(input, { followUpTime: this.state.followUpDate });
      // if (this.state.followUpDate < moment().format("YYYY-MM-DD")) {
      //   Modal.warning({
      //     title: "Save Treatment Failed",
      //     content: "Follow-up date must be larger than today.",
      //     centered: true
      //   });
      //   return;
      // }
      if (this.state.endDate < this.state.followUpDate) {
        Modal.warning({
          title: "Save Treatment Failed",
          content: "End date must be larger than Follow-up date.",
          centered: true
        });
        return;
      }
    }
    this.setState(({ loadings }) => {
      const newLoadings = [...loadings];
      newLoadings[index] = true;

      return {
        loadings: newLoadings
      };
    });
    this.props.updateFootTreatment(input, this);
  };

  render() {
    const { Option } = Select;
    return (
      <>
        <Button
          type="link"
          onClick={this.showModal}
          style={{ marginRight: 20, marginLeft: -20 }}
        >
          Update
        </Button>
        <Modal
          title="Update Treatment"
          visible={this.state.visible}
          footer={null}
          centered={true}
          closable={false}
          width={"720"}
        >
          <div>
            <Row gutter={[16, 24]}>
              <Col span={12}>
                <Select
                  value={this.state.selectTreatment}
                  onChange={this.handleTreatmentChange}
                  style={{ width: "100%" }}
                  placeholder={"Select a treatment"}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  optionFilterProp="children"
                >
                  {TREATMENT.map((p, index) => {
                    return (
                      <Option value={p} key={index}>
                        {p}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
              <Col span={6}>
                <Select
                  value={this.state.selectFoot}
                  onChange={this.handleFootChange}
                  style={{ width: "100%" }}
                  placeholder="Select foot"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value={"N/A"} key={"N/A"}>
                    N/A
                  </Option>
                  {FOOT.map((p, index) => {
                    return (
                      <Option value={p} key={index}>
                        {p}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>
            <Row gutter={[16, 24]}>
              <Col span={6}>
                <label className="sr-required">Start Date</label>
                <DatePicker
                  onChange={this.onStartDateChanged}
                  value={moment(this.state.startDate)}
                  format={dateFormat}
                  placeholder={dateFormat}
                  allowClear={false}
                />
              </Col>
              <Col span={6}>
                <label>End Date</label>
                <DatePicker
                  onChange={this.onEndDateChanged}
                  value={
                    this.state.endDate ? moment(this.state.endDate) : undefined
                  }
                  format={dateFormat}
                  placeholder={dateFormat}
                  allowClear={true}
                />
              </Col>
              <Col span={6} offset={6}>
                <label>Set follow-up</label>
                <DatePicker
                  onChange={this.onFollowUpDateChanged}
                  value={
                    this.state.followUpDate
                      ? moment(this.state.followUpDate)
                      : undefined
                  }
                  format={dateFormat}
                  placeholder={dateFormat}
                  allowClear={true}
                />
              </Col>
            </Row>
            <Row
              justify="center"
              gutter={[0, 32]}
              style={{ textAlign: "center" }}
            >
              <Col span={3} offset={9}>
                <Button
                  type="primary"
                  loading={this.state.loadings[0]}
                  onClick={() => this.saveTreatment(0)}
                >
                  Save
                </Button>
              </Col>
              <Col span={3}>
                <Button
                  type="default"
                  onClick={() => {
                    this.setState({ visible: false });
                  }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProp = state => ({
  doctorDetails: state.patientsListStore.doctorDetails
});
const mapDispatchToProp = dispatch => ({
  updateFootTreatment: (input, self) =>
    dispatch(updateFootTreatment(input, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(UpdateTreatment);
