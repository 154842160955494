import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "antd";
import "./patient-medical.css";
import moment from "moment";
import MedicalPop from "./medicalPop";
import DividLine from "./dividLine";
import AddCondition from "./addCondition";
import _ from "lodash";
import { updateFootConditionStatus } from "../action/patientMedicalAction";
import UpdateCondition from "./updateCondition";

/**
 *  Created by shengli.zhang on 4/30/21 3:17 PM
 */
const DOWN = require("../img/down_blue.png");
const UP = require("../img/up_blue.png");
class FootCondition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addAnotherCondition: false,
      initWithNo: false,
      loadings: [],
      viewAll: true,
      conditionData: []
    };
  }

  componentDidMount() {
    this.setState({
      conditionData:
        this.props.type !== "ADD" && this.props.type !== "PROFILE"
          ? this.props.serviceInfo && this.props.serviceInfo.footConditions
            ? this.props.serviceInfo.footConditions
            : []
          : this.props.patientDetails &&
            this.props.patientDetails.patientMedicalInfo &&
            this.props.patientDetails.patientMedicalInfo.footConditions
          ? this.props.patientDetails.patientMedicalInfo.footConditions
          : []
    });
  }

  markHealed = (userId, reportTime, status, index) => {
    this.setState(({ loadings }) => {
      const newLoadings = [...loadings];
      newLoadings[index] = true;
      return {
        loadings: newLoadings
      };
    });
    const input = {
      userId,
      reportTime,
      status,
      endTime: moment().format("YYYY-MM-DD")
    };
    this.props.updateFootConditionStatus(input, this, index);
  };

  markActive = (userId, reportTime, status, index) => {
    this.setState(({ loadings }) => {
      const newLoadings = [...loadings];
      newLoadings[index] = true;
      return {
        loadings: newLoadings
      };
    });
    const input = {
      userId,
      reportTime,
      status
    };
    this.props.updateFootConditionStatus(input, this, index);
  };

  renderCard(p, index) {
    return (
      <div
        key={index}
        style={{
          backgroundColor: p.status === "Healed" ? "#3F3F3F" : "#F8F8FA",
          borderRadius: 5,
          width: "60%",
          minWidth: 500,
          display: this.state.viewAll ? "block" : "none",
          marginTop: 20
        }}
      >
        <div style={{ display: this.state.viewAll ? "flex" : "none" }}>
          <div style={{ width: p.status === "Healed" ? "80%" : "65%" }}>
            <div
              style={{
                fontWeight: "bold",
                fontSize: 15,
                marginLeft: 20,
                paddingTop: 10,
                color: p.status === "Healed" ? "#fff" : "#000"
              }}
            >
              {p.footCondition.toUpperCase()}, {p.foot} Foot, {p.footArea}
            </div>
            <div
              style={{
                fontSize: 14,
                marginLeft: 20,
                paddingTop: 5,
                color: p.status === "Healed" ? "#fff" : "#000"
              }}
            >
              <span style={{ display: "inline-block", width: 120 }}>
                Start Date:
              </span>
              <strong
                style={{
                  fontWeight: "bold",
                  color: p.status === "Healed" ? "#fff" : "#000"
                }}
              >
                {moment(p.startTime).format("MM/DD/YYYY")}
              </strong>
            </div>
            <div
              style={{
                fontSize: 14,
                marginLeft: 20,
                paddingTop: 5,
                paddingBottom: p.followUpTime ? 0 : 10,
                color: p.status === "Healed" ? "#fff" : "#000"
              }}
            >
              <span style={{ display: "inline-block", width: 120 }}>
                End Date:
              </span>
              <span
                style={{
                  color: p.endTime
                    ? p.status === "Healed"
                      ? "#fff"
                      : "#000"
                    : "#8A8E9D",
                  fontWeight: p.endTime ? "bold" : "0"
                }}
              >
                {p.endTime ? moment(p.endTime).format("MM/DD/YYYY") : "Ongoing"}
              </span>
            </div>
            {p.followUpTime && (
              <div
                style={{
                  fontSize: 14,
                  marginLeft: 20,
                  paddingTop: 5,
                  paddingBottom: 10,
                  color: p.status === "Healed" ? "#fff" : "#000"
                }}
              >
                <span style={{ display: "inline-block", width: 120 }}>
                  Follow-up Date:
                </span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: p.status === "Healed" ? "#fff" : "#000"
                  }}
                >
                  {moment(p.followUpTime).format("MM/DD/YYYY")}
                </span>
              </div>
            )}
          </div>
          {this.props.type !== "ADD" &&
          !this.props.canEdit ? null : p.status === "Healed" ? (
            <div style={{ display: "flex", width: "20%" }}>
              <Button
                type="link"
                loading={this.state.loadings[index]}
                onClick={() =>
                  this.markActive(
                    this.props.patientDetails.userId,
                    p.reportTime,
                    "Reactivate",
                    index
                  )
                }
                style={{ margin: "0 auto" }}
              >
                Reactivate
              </Button>
            </div>
          ) : (
            <div style={{ display: "flex", width: "35%" }}>
              <Button
                type="link"
                loading={this.state.loadings[index]}
                onClick={() =>
                  this.markHealed(
                    this.props.patientDetails.userId,
                    p.reportTime,
                    "Healed",
                    index
                  )
                }
              >
                Mark Healed
              </Button>
              <UpdateCondition
                conditionInfo={p}
                updateConditionData={data => {
                  let nextConditionData = [];
                  if (
                    _(this.state.conditionData).find({
                      reportTime: data.reportTime
                    })
                  ) {
                    nextConditionData = this.state.conditionData.map(item => {
                      if (item.reportTime === data.reportTime) {
                        return {
                          ...data
                        };
                      }
                      return item;
                    });
                  } else {
                    nextConditionData = [...this.state.conditionData, data];
                  }
                  this.setState({
                    conditionData: nextConditionData
                  });
                  if (this.props.onChangeFootConditionData) {
                    this.props.onChangeFootConditionData(nextConditionData);
                  }
                }}
              />
            </div>
          )}
        </div>
        {p.status === "Healed" && (
          <div
            style={{
              fontSize: 14,
              marginLeft: 20,
              paddingBottom: 10,
              color: "#fff"
            }}
          >
            This condition has been marked as done.
          </div>
        )}
      </div>
    );
  }

  render() {
    let hasData = this.state.conditionData.length > 0;
    let initialState = !hasData;
    if (this.state.addAnotherCondition) {
      initialState = false;
    }
    const initReadonly = initialState && !this.props.canEdit;

    return (
      <div>
        <div
          style={{
            fontWeight: "bold",
            fontSize: 18,
            marginBottom: 10,
            marginTop: 10,
            display: "flex",
            alignItems: "baseline"
          }}
        >
          Current Condition
          <MedicalPop
            title="Current Conditions"
            content={
              "Provider reported conditions collected from patient’s EMR."
            }
          />
          <Button
            type="link"
            size="small"
            style={{
              display: hasData ? "block" : "none"
            }}
            onClick={() => {
              this.setState({ viewAll: !this.state.viewAll });
            }}
          >
            View all
            <img
              src={this.state.viewAll ? UP : DOWN}
              style={{ width: 12, height: 16, marginLeft: 5 }}
            />
          </Button>
        </div>
        {this.props.type === "PROFILE" && (
          <div>
            For view only. To change any conditions, go to{" "}
            <Button
              type="link"
              style={{ marginLeft: -15 }}
              onClick={() => {
                this.props.editFootMedical("2");
              }}
            >
              DMT
            </Button>
          </div>
        )}
        {hasData &&
          _.orderBy(
            this.state.conditionData,
            ["status", "reportTime"],
            ["asc", "desc"]
          ).map((p, i) => this.renderCard(p, i))}
        {initReadonly ? (
          <div>No active conditions at this time.</div>
        ) : initialState ? (
          this.state.initWithNo ? (
            <>
              <div>No active conditions at this time.</div>
              <Button
                type="link"
                style={{
                  marginTop: 20,
                  marginLeft: -16
                }}
                onClick={() => this.setState({ addAnotherCondition: true })}
              >
                + Add a condition
              </Button>
            </>
          ) : (
            <>
              <div>
                Nothing has been added yet. Does the patient have any active
                conditions?
              </div>
              <div style={{ marginTop: 30, marginBottom: 30 }}>
                <Button
                  style={{ marginRight: 12 }}
                  onClick={() => this.setState({ addAnotherCondition: true })}
                >
                  Yes
                </Button>
                <Button
                  onClick={() => {
                    this.setState({ initWithNo: true });
                    this.props.onChangeFootConditionStatus("No");
                  }}
                >
                  No change to condition
                </Button>
              </div>
            </>
          )
        ) : (
          <>
            {this.state.addAnotherCondition && (
              <AddCondition
                cancelCondition={() => {
                  this.setState({ addAnotherCondition: false });
                }}
                updateConditionData={data => {
                  const nextConditionData = [...this.state.conditionData, data];
                  this.setState({
                    conditionData: nextConditionData,
                    addAnotherCondition: false
                  });
                  if (this.props.onChangeFootConditionData) {
                    this.props.onChangeFootConditionData(nextConditionData);
                  }
                }}
              />
            )}
            {this.props.canEdit && (
              <Button
                type="link"
                style={{
                  marginTop: 20,
                  marginLeft: -16
                }}
                onClick={() => this.setState({ addAnotherCondition: true })}
              >
                + Add a condition
              </Button>
            )}
          </>
        )}
        <DividLine />
      </div>
    );
  }
}

const mapStateToProp = state => ({
  patientDetails: state.patientsListStore.doctorDetails
});
const mapDispatchToProp = dispatch => ({
  updateFootConditionStatus: (input, self, index) =>
    dispatch(updateFootConditionStatus(input, self, index))
});
export default connect(mapStateToProp, mapDispatchToProp)(FootCondition);
