/**
 * Create by shengli.zhang on 2019/4/19
 */

export function removeAllSpace(str) {
  return str.replace(/\s+/g, "");
}

export function lowercaseRmSpace(str) {
  return str.replace(/\s+/g, "").toLowerCase();
}

export function lowercase(str) {
  return str.toLowerCase();
}

export function uppercaseRmSpace(str) {
  return str.replace(/\s+/g, "").toUpperCase();
}

export function uppercase(str) {
  return str.toUpperCase();
}

export function uppercaseFirstStr(str) {
  return str
    .split(" ")
    .map(function(word) {
      return word.replace(/^\S/, s => s.toUpperCase());
    })
    .join(" ");
}
