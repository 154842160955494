import _ from "lodash";

/**
 *  Created by shengli.zhang on 2020-01-08 14:45
 */

export function getPatientHubType(hubs) {
  let hubType = "None";
  if (hubs.length === 0) {
    return hubType;
  }
  const hubList = hubs.map(item => {
    return item.hubId;
  });
  const hub2G = hubList.filter(t => t.includes("H2G")).length > 0 ? "2G" : "";
  const hub4G = hubList.filter(t => t.includes("H4G")).length > 0 ? "4G" : "";
  const hubWifi =
    hubList.filter(t => !t.includes("H4G") && !t.includes("H2G")).length > 0
      ? "Wifi"
      : "";
  let hubTypeList = [hubWifi, hub2G, hub4G];
  return hubTypeList
    .filter(t => !_.isEmpty(t))
    .map(p => {
      return p;
    })
    .join(", ");
}
