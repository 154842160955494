import moment from "moment";
import { Modal as AntdModal } from "antd";

export function limitMonth(value) {
  const re = /^[0-9]+.?[0-9]*/;
  if (!re.test(value)) {
    return false;
  } else {
    if (value > 12 || value < 1) {
      return false;
    } else if (value <= 12 && value >= 1) {
      return true;
    }
  }
}
export function limitDay(value) {
  const re = /^[0-9]+.?[0-9]*/;
  if (!re.test(value)) {
    return false;
  } else {
    if (value > 31 || value < 1) {
      return false;
    } else if (value <= 31 && value >= 1) {
      return true;
    }
  }
}
export function limitYear(value) {
  const re = /^[0-9]+.?[0-9]*/;
  if (!re.test(value)) {
    return false;
  } else {
    if (value > new Date().getFullYear() || value < 1900) {
      return false;
    } else if (value <= new Date().getFullYear() && value >= 1900) {
      return true;
    }
  }
}

export function checkBirthdayValid(birthday) {
  const startYear = moment()
    .add(-200, "year")
    .format("YYYY");
  const endYear = moment().format("YYYY");
  const birDate = moment(birthday).format("YYYY");
  if (birDate < startYear || birDate > endYear) {
    AntdModal.warning({
      title: "Birthday is invalid",
      content: `Year of the birthday must between ${startYear} and ${endYear}.`,
      centered: true
    });
    return false;
  }
  return true;
}
