import React, { Component } from "react";
import { connect } from "react-redux";
import { Select, Input, Modal, DatePicker, Button, Row, Col } from "antd";
import { CONDITIONS, FOOT, SYMPTOMS_AREA } from "../tools/patient-medical";
import _ from "lodash";
import moment from "moment";
import { addFootCondition } from "../action/patientMedicalAction";

/**
 *  Created by shengli.zhang on 4/30/21 3:17 PM
 */
const dateFormat = "MM/DD/YYYY";
class AddCondition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectCondition: undefined,
      selectFoot: undefined,
      selectArea: [],
      otherCondition: "",
      startDate: moment().format("YYYY-MM-DD"),
      endDate: undefined,
      followUpDate: undefined,
      loadings: []
    };
  }
  handleSymptomChange = value => {
    this.setState({ selectCondition: value });
  };

  handleFootChange = value => {
    this.setState({ selectFoot: value });
  };

  handleAreaChange = value => {
    this.setState({ selectArea: value });
  };

  onOtherConditionChange = event => {
    this.setState({ otherCondition: event.target.value });
  };

  onStartDateChanged = (date, dateString) => {
    if (date) {
      this.setState({
        startDate: moment(date).format("YYYY-MM-DD")
      });
    }
  };

  onEndDateChanged = (date, dateString) => {
    if (date) {
      this.setState({
        endDate: moment(date).format("YYYY-MM-DD")
      });
    } else {
      this.setState({
        endDate: undefined
      });
    }
  };

  onFollowUpDateChanged = (date, dateString) => {
    if (date) {
      this.setState({
        followUpDate: moment(date).format("YYYY-MM-DD")
      });
    } else {
      this.setState({
        followUpDate: undefined
      });
    }
  };

  saveCondition = index => {
    if (!this.state.selectCondition) {
      Modal.warning({
        title: "Save Condition Failed",
        content: "Please select a condition.",
        centered: true
      });
      return;
    }
    if (!this.state.selectFoot) {
      Modal.warning({
        title: "Save Condition Failed",
        content: "Please select foot.",
        centered: true
      });
      return;
    }
    if (this.state.selectArea.length === 0) {
      Modal.warning({
        title: "Save Condition Failed",
        content: "Please select area.",
        centered: true
      });
      return;
    }
    if (
      this.state.selectCondition === "Other" &&
      _.isEmpty(this.state.otherCondition)
    ) {
      Modal.warning({
        title: "Save Condition Failed",
        content: "Please input a condition.",
        centered: true
      });
      return;
    }
    const input = {
      userId: this.props.doctorDetails.userId,
      reportTime: moment().format("YYYY-MM-DDTHH:mm:ss.SSS"),
      footCondition:
        this.state.selectCondition === "Other"
          ? this.state.otherCondition
          : this.state.selectCondition,
      foot: this.state.selectFoot,
      footArea: this.state.selectArea
        .map(p => {
          return p;
        })
        .join(","),
      startTime: this.state.startDate
    };
    if (this.state.endDate) {
      Object.assign(input, { endTime: this.state.endDate });
      if (this.state.endDate < moment().format("YYYY-MM-DD")) {
        Modal.warning({
          title: "Save Condition Failed",
          content: "End date must be larger than today.",
          centered: true
        });
        return;
      }
      if (this.state.endDate < this.state.startDate) {
        Modal.warning({
          title: "Save Condition Failed",
          content: "End date must be larger than Start date.",
          centered: true
        });
        return;
      }
    }
    if (this.state.followUpDate) {
      Object.assign(input, { followUpTime: this.state.followUpDate });
      if (this.state.followUpDate < moment().format("YYYY-MM-DD")) {
        Modal.warning({
          title: "Save Condition Failed",
          content: "Follow-up date must be larger than today.",
          centered: true
        });
        return;
      }
      if (this.state.endDate < this.state.followUpDate) {
        Modal.warning({
          title: "Save Condition Failed",
          content: "End date must be larger than Follow-up date.",
          centered: true
        });
        return;
      }
    }
    this.setState(({ loadings }) => {
      const newLoadings = [...loadings];
      newLoadings[index] = true;

      return {
        loadings: newLoadings
      };
    });
    this.props.addFootCondition(input, this);
  };

  render() {
    const { Option } = Select;
    return (
      <div className="border-container" style={{ padding: 20, maxWidth: 720 }}>
        <Row gutter={[16, 24]}>
          <Col span={12}>
            <Select
              value={this.state.selectCondition}
              onChange={this.handleSymptomChange}
              style={{ width: "100%" }}
              placeholder={"Select a condition"}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              optionFilterProp="children"
            >
              {CONDITIONS.map((p, index) => {
                return (
                  <Option value={p} key={index}>
                    {p}
                  </Option>
                );
              })}
            </Select>
            {this.state.selectCondition === "Other" && (
              <div style={{ marginTop: 12 }}>
                <Input
                  value={this.state.otherCondition}
                  placeholder="Other condition"
                  maxLength={25}
                  onChange={this.onOtherConditionChange}
                />
              </div>
            )}
          </Col>
          <Col span={6}>
            <Select
              value={this.state.selectFoot}
              onChange={this.handleFootChange}
              style={{ width: "100%" }}
              placeholder="Select foot"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              optionFilterProp="children"
            >
              {FOOT.map((p, index) => {
                return (
                  <Option value={p} key={index}>
                    {p}
                  </Option>
                );
              })}
            </Select>
          </Col>
          <Col span={6}>
            <Select
              mode="multiple"
              value={this.state.selectArea}
              onChange={this.handleAreaChange}
              style={{ width: "100%" }}
              placeholder="Select area"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              optionFilterProp="children"
            >
              {SYMPTOMS_AREA.map((p, index) => {
                return (
                  <Option value={p} key={index}>
                    {p}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row>
        <Row gutter={[16, 24]}>
          <Col span={6}>
            <label className="sr-required">Start Date</label>
            <DatePicker
              onChange={this.onStartDateChanged}
              value={moment(this.state.startDate)}
              format={dateFormat}
              placeholder={dateFormat}
              allowClear={false}
            />
          </Col>
          <Col span={6}>
            <label>End Date</label>
            <DatePicker
              onChange={this.onEndDateChanged}
              value={
                this.state.endDate ? moment(this.state.endDate) : undefined
              }
              format={dateFormat}
              placeholder={dateFormat}
              allowClear={true}
            />
          </Col>
          <Col span={6} offset={6}>
            <label>Set follow-up</label>
            <DatePicker
              onChange={this.onFollowUpDateChanged}
              value={
                this.state.followUpDate
                  ? moment(this.state.followUpDate)
                  : undefined
              }
              format={dateFormat}
              placeholder={dateFormat}
              allowClear={true}
            />
          </Col>
        </Row>
        <Row justify="center" gutter={[0, 32]} style={{ textAlign: "center" }}>
          <Col span={3} offset={9}>
            <Button
              type={"primary"}
              loading={this.state.loadings[0]}
              onClick={() => this.saveCondition(0)}
            >
              Save
            </Button>
          </Col>
          <Col span={3}>
            <Button type={"default"} onClick={this.props.cancelCondition}>
              Cancel
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProp = state => ({
  doctorDetails: state.patientsListStore.doctorDetails
});
const mapDispatchToProp = dispatch => ({
  addFootCondition: (input, self) => dispatch(addFootCondition(input, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(AddCondition);
