import React, { Component } from "react";
import { InfoCircleFilled } from "@ant-design/icons";
import { Popup } from "semantic-ui-react";
/**
 *  Created by shengli.zhang on 4/30/21 2:11 PM
 */

export default class SpecialCasePop extends Component {
  render() {
    return (
      <div>
        <Popup
          trigger={
            <InfoCircleFilled
              style={{
                color: "#D3D6E1",
                marginLeft: 5
              }}
            />
          }
          position="top center"
          hoverable
          flowing
          style={{ backgroundColor: "#F1F1F1", width: 450 }}
        >
          <Popup.Header
            style={{
              marginTop: 5,
              fontSize: 16
            }}
          >
            Special Cases
          </Popup.Header>
          <Popup.Content>
            <p>Special cases will be "Yes" if any of the following are true:</p>
            <li>
              Patient medical history indicates amputation (foot, MTS, hallux,
              digits)
            </li>
            <li>There is a current active medical condition</li>
            <li>
              There is a current treatment (except compression stockings,
              offloading insoles)
            </li>
          </Popup.Content>
        </Popup>
      </div>
    );
  }
}
