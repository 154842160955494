import { Modal } from "antd";
/**
 *  Created by shengli.zhang on 12/8/20 4:03 PM
 */

const modalInfo = {
  success: (title = "Info", content) => {
    Modal.success({
      title,
      content,
      centered: true
    });
  },
  info: (title = "Info", content) => {
    Modal.info({
      title,
      content,
      centered: true
    });
  },
  warning: (title = "Warning", content) => {
    Modal.warning({
      title,
      content,
      centered: true
    });
  },
  error: (title = "Error", content) => {
    Modal.error({
      title,
      content,
      centered: true
    });
  }
};

export default modalInfo;
