import React, { Component } from "react";
/**
 *  Created by shengli.zhang on 4/30/21 3:17 PM
 */

export default class DividLine extends Component {
  render() {
    return (
      <div>
        <div
          style={{
            marginTop: 20,
            backgroundColor: "#E9EAEE",
            height: 1
          }}
        />
      </div>
    );
  }
}
