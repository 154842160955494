import { Control } from "react-keeper";
import { connect } from "react-redux";
import ReportHeaderBase from "./ReportHeaderBase";

class ReportHeader99453_4 extends ReportHeaderBase {

  title = 'RPM: 99453 & 99454'

  terminology = [
    {
      term: 'Setup Service:',
      body: 'Initial setup and patient education for Siren Socks.'
    },
    {
      term: 'Recording Service:',
      body: 'Remote monitoring of physiologic parameter(s) in a 30-day period. Periods will vary between patients based on prescription date and utilization.'
    },
    {
      term: 'Account Created:',
      body: "Patient's account creation date in the Siren Dashboard."
    },
    {
      term: 'Account Activation Date:',
      body: 'First date that patient logged into the Siren App.'
    },
    {
      term: 'Socks Dispensed Date:',
      body: 'Date that Siren Socks were first provided to the patient (issued by clinic or shipped by Siren).'
    },
    {
      term: 'Estimated Start:',
      body: 'Approximate start of the period. Dependent on Siren Socks utilization by the patient.'
    },
    {
      term: 'Days Recorded:',
      body: 'Number of daily recordings based on Siren Sock utilization.'
    },
    {
      term: 'Days Remaining:',
      body: 'Number of days remaining in the period, based on the estimated start date.'
    },
    {
      term: 'Run Rate:',
      body: 'Equals (# of Days Recorded / Days Run) * Days in Period.'
    }
  ]

  tabs = [
    // {
    //   name: 'Monitoring',
    //   path: '/RecordingReport',
    //   onClick: () => Control.go("/RecordingReport")
    // },
    {
      name: 'Needs Review',
      path: '/RecordingNeedsReview',
      onClick: () => Control.go("/RecordingNeedsReview")
    },
    {
      name: 'Reviewed',
      path: '/RecordingReviewed',
      onClick: () => Control.go("/RecordingReviewed")
    }
  ]

}

const mapStateToProp = state => ({
  reviewRecordsCount: state.monthlyReportStore.count99453Unbilled + state.monthlyReportStore.count99454Unbilled
});

export default connect(mapStateToProp, null)(ReportHeader99453_4);
