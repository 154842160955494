import { Control } from "react-keeper";
import { connect } from "react-redux";
import ReportHeaderBase from "./ReportHeaderBase";

class ReportHeader99426_7 extends ReportHeaderBase {

  title = 'PCM: 99426 & 99427'

  terminology = [
    {
      term: 'PCM Monitoring Service:',
      body: 'Overview of time spent by clinical staff/physician with patient/caregiver in a calendar month.',
    },
    {
      term: 'Account Created:',
      body: "Patient's account creation date in the Siren Dashboard.",
    },
    {
      term: 'Account Activation Date:',
      body: 'First date that patient logged into the Siren App.',
    },
    {
      term: 'Total Time:',
      body: 'Sum of time tracked by staff in "Data Monitoring Tracker".',
    },
    {
      term: 'Days Remaining:',
      body: 'Number of days remaining in the month.',
    },
  ]

  tabs = [
    // {
    //   name: 'Monitoring',
    //   path: '/PCMReport',
    //   onClick: () => Control.go("/PCMReport")
    // },
    {
      name: 'Needs Review',
      path: '/PCMNeedsReview',
      onClick: () => Control.go("/PCMNeedsReview")
    },
    {
      name: 'Reviewed',
      path: '/PCMReviewed',
      onClick: () => Control.go("/PCMReviewed")
    }
  ]

}

const mapStateToProp = state => ({
  reviewRecordsCount: state.monthlyReportStore.countPCMUnbilled
});

export default connect(mapStateToProp, null)(ReportHeader99426_7);
