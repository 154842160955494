import { API, graphqlOperation } from "aws-amplify";
import _ from "lodash";
import _api from "../graphql/_api";
import * as TYPES from "../types/types";
import { Modal as AntdModal } from "antd";
/**
 *  Created by shengli.zhang on 8/16/21 10:28 AM
 */

export function listCarePlan(self, userId) {
  return async dispatch => {
    try {
      dispatch(changeListCarePlanStatus([]));
      const newEvent = await API.graphql(
        graphqlOperation(_api.listCarePlan(), { userId })
      );
      self.closeModal();
      if (newEvent.data.getCarePlans === null) {
        dispatch(changeListCarePlanStatus([]));
      } else {
        dispatch(
          changeListCarePlanStatus(
            _.orderBy(newEvent.data.getCarePlans, ["createdAt"], ["asc"])
          )
        );
      }
    } catch (e) {
      self.closeModal();
      console.log(`get list care plan error: ${e}`);
    }
  };
}

export function createCarePlan(input, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.createCarePlan(), { input })
      );
      self.closeModal();
      console.log(newEvent);
      const listCarePlans = _.clone(self.props.listCarePlans);
      if (newEvent.data.createCarePlan) {
        listCarePlans.push(newEvent.data.createCarePlan);
        dispatch(
          changeListCarePlanStatus(
            _.orderBy(listCarePlans, ["createdAt"], ["asc"])
          )
        );
      }
    } catch (err) {
      console.log("create care plan error: ", JSON.stringify(err));
    }
  };
}

export function deleteDocument(input, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.deleteCarePlan(), { input })
      );
      if (newEvent) {
        self.props.reviewDocument(false);
        self.setState(({ loadings }) => {
          const newLoadings = [...loadings];
          newLoadings[0] = false;
          return {
            loadings: newLoadings
          };
        });
        const listCarePlans = _.filter(self.props.listCarePlans, function(
          item
        ) {
          return item.createdAt !== input.createdAt;
        });
        dispatch(
          changeListCarePlanStatus(
            _.orderBy(listCarePlans, ["createdAt"], ["asc"])
          )
        );
        self.handleClose();
      }
    } catch (err) {
      self.setState(({ loadings }) => {
        const newLoadings = [...loadings];
        newLoadings[0] = false;
        return {
          loadings: newLoadings
        };
      });
      AntdModal.error({
        title: "Delete CarePlan Error",
        content: `${JSON.stringify(err)}`,
        centered: true
      });
      console.log("delete document error: ", JSON.stringify(err));
    }
  };
}

function changeListCarePlanStatus(listCarePlan) {
  return {
    type: TYPES.LIST_CARE_PLAN,
    text: listCarePlan
  };
}
