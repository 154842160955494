import moment from "moment";
/**
 * Create by shengli.zhang on 2019/4/15
 */

function GetDateStr(AddDayCount) {
  var dd = new Date();
  dd.setDate(dd.getDate() + AddDayCount); //获取AddDayCount天后的日期
  var y = dd.getFullYear();
  var m = dd.getMonth() + 1; //获取当前月份的日期
  m = m < 10 ? "0" + m : m;
  var d = dd.getDate();
  d = d < 10 ? "0" + d : d;
  return y + "-" + m + "-" + d;
}

export function isToday(dateStamp) {
  let momentDate = moment(dateStamp).format("YYYY-MM-DD");
  return momentDate === GetDateStr(0);
}
